import {Pages}                   from "./Pages";
import ReactDOM                  from "react-dom";
import React                    from "react";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";

export class Contacts extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);

        ReactDOM.render(
            <EntitiesSearchDropList
                placeholder={"поиск контактов, в том числе по ФИО слушателя"}
                entity={Entities.CONTACT}/>,
            document.getElementById('list-search-form-component'),
        );

    }
}
